import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mobile } from '../../styles/functions'

/**
 * Advertisement styles
 */
export const ButtonLink = styled.a`
  padding: 0.8rem 2rem;
  font-weight: 900;
  border-radius: 0.4rem;
  height: fit-content;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.1rem;
  word-break: keep-all;
  white-space: pre;
  color: white;
  background-color: #52a5c9;
  border: 1px solid #ace6ff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  &:hover {
    background-color: #6abcdf;
  }
  ${mobile(css`
    width: 100%;
    justify-content: center;
  `)}
`

export const Wrapper = styled.div`
  display: block;
  padding: 20px 0;
  flex-grow: 1;
  ${mobile(css`
    padding: 0.5rem 0;
  `)}
  #topAd2 .bing-result {
    font-size: 20px;
    margin-bottom: 10px;
  }

  #topAd2 .main-unit {
    border-radius: 8px;
    background-color: rgba(31, 21, 46, 1);
    text-decoration: none;
    padding: 28px 32px 32px 32px;
    box-sizing: border-box;
    transition: 0.2s background-color ease;
  }

  #topAd2 .unit-title a {
    color: #1964d3 !important;
    font-size: 23px;
    margin-bottom: 5px;
  }

  #topAd2 .unit-url a {
    color: green !important;
    font-size: 16px;
  }

  #topAd2 .unit-desc {
    width: 60%;
  }

  #topAd2 .unit-desc a {
    font-size: 18px;
    font-weight: 500;
    color: #fff !important;
  }

  #topAd2 .sponsored-listing {
    display: none;
  }
`

export const Loader = styled.div`
  display: block;
`

export const Item = styled.div`
  display: block;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  color: #ffffff;

  a {
    display: inline-block;
    width: 100%;
    color: inherit;
    text-decoration: none;
    padding: 28px 32px 32px 32px;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s background-color ease;
    background-color: rgba(31, 21, 46, 1);

    &:after {
      content: '›';
      position: absolute;
      right: 20px;
      top: 24px;
      font-size: 48px;
      padding: 8px 14px 16px 14px;
      border: 3px rgba(221, 56, 116, 1) solid;
      border-radius: 8px;
      line-height: 20px;
      color: rgba(221, 56, 116, 1);
    }

    &:hover {
      background-color: rgba(59, 44, 88, 1);
    }

    .ad-title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    .ad-description {
      font-size: 18px;
      font-weight: 500;
    }

    .ad-button {
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      padding: 16px 72px 16px 20px;
      background-color: rgba(221, 56, 116, 1);
      border-radius: 8px;
      margin-top: 32px;
      position: relative;

      &:after {
        content: '›';
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        padding: 2px 10px 6px 10px;
        border: 0;
        border-radius: 50%;
        line-height: 24px;
        color: rgba(221, 56, 116, 1);
        background-color: #ffffff;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a.ad-yahoo .ad-title {
    color: #1964d3;
    font-size: 23px;
    margin-bottom: 5px;
  }

  a.ad-yahoo .ad-link {
    color: green;
    font-size: 16px;
  }

  a.ad-yahoo:after {
    content: none !important;
  }

  .ad-color-scheme-2 & {
    color: rgba(221, 56, 116, 1);

    a {
      background-color: rgba(242, 242, 242, 1);

      &:hover {
        background-color: rgba(229, 229, 229, 1);
      }

      .ad-description {
        color: #201430;
      }

      .ad-button {
        color: #ffffff;
      }
    }
  }

  .ad-color-scheme-3 & {
    color: #ffffff;

    a {
      background-color: rgba(26, 19, 196, 1);

      &:after {
        border-color: rgba(110, 191, 75, 1);
        color: rgba(110, 191, 75, 1);
      }

      &:hover {
        background-color: rgba(41, 30, 162, 1);
      }

      .ad-button {
        color: #ffffff;
        background-color: rgba(110, 191, 75, 1);

        &:after {
          color: rgba(110, 191, 75, 1);
        }
      }
    }
  }

  .ad-color-scheme-4 & {
    color: #ffffff;

    a {
      background-color: rgba(242, 242, 242, 1);

      &:after {
        border-color: rgba(26, 19, 196, 1);
        color: rgba(26, 19, 196, 1);
      }

      &:hover {
        background-color: rgba(229, 229, 229, 1);
      }

      .ad-title {
        color: rgba(26, 19, 196, 1);
      }

      .ad-description {
        color: #201430;
      }

      .ad-button {
        color: #ffffff;
        background-color: rgba(26, 19, 196, 1);

        &:after {
          color: rgba(26, 19, 196, 1);
        }
      }
    }
  }
`

export const AdItem = styled.div`
  display: block;
  margin: 10px 0;
  border-radius: 10px;
  padding: 15px;
  background-color: #f8f8f8;
  &:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  display: flex;
  ${mobile(css`
    flex-direction: column;
  `)}
  .btn-link-container {
    width: 18%;
    min-width: 12rem;
    ${mobile(css`
      width: 100%;
    `)}
  }

  .left-block {
    font-family: 'Inter';

    a {
      display: block;
      color: black;
      width: fit-content;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .ad-title {
      width: fit-content;
      margin-bottom: 7px;
      b {
        font-weight: 500;
      }
    }

    .ad-description {
      width: fit-content;
      font-size: 14px;
      margin-bottom: 5px;
      b {
        font-weight: 500;
      }
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .ad-link {
      color: black;
      font-size: 1rem;
      width: fit-content;
      margin-bottom: 0.8rem;
    }
  }

  .right-block {
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    justify-content: center;

    .label {
      text-decoration: underline;
      color: #3063e7;
    }
  }

  ${mobile(css`
    grid-template-columns: 1fr;
    grid-gap: 15px;

    .left-block {
      width: 100%;
      margin-bottom: 12px;
    }

    .right-block {
      width: 100%;
      display: block;

      .label {
        margin-bottom: 5px;
      }
    }

    .ad-description {
    }
  `)}
`

export const AdHead = styled.div`
  text-align: left;
  color: #7a7a7a;
  font-size: 13px;
`

export const AdContainer = styled.div`
  .units {
    list-style-type: none;
    padding: 0;
  }

  .unit {
    background: #f8f8f8;
    margin: 0.5em 0 1.25em 0;
    padding: 0.5rem;
    border-radius: 5px;
    position: relative;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col-lg-10,
  .col-lg-2 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .title {
    margin-bottom: 0;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 0;
  }

  .host {
    margin-bottom: 0;
    line-height: 30px;
  }

  a {
    word-break: break-word;
    color: #212529;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .cta {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 7px 0;
  }

  .cta_button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 8px 0;
    width: 100%;
    border-radius: 5px;
    letter-spacing: 0.075rem;
    font-weight: 700;
    background: #f7a518;

    &:hover {
      background-color: #0274b2 !important;
      color: #000;
      text-decoration: underline;
      border: 1px solid #000;
    }
  }

  .cta_text {
    margin: 0;
  }

  .sponsored_text {
    color: #9b9b9b;
    font-size: 0.6875rem;
    margin-bottom: -10px !important;
  }

  ${mobile(css`
    .col-lg-10,
    .col-lg-2 {
      flex: auto;
      max-width: 100%;
    }

    .cta_button {
      padding: 20px 0;
    }

    .bottom {
      .unit {
        display: flex;
        flex-direction: column;

        .col-lg-10 {
          order: 2;
        }

        .col-lg-2 {
          order: 1;
        }
      }
    }
  `)}
`
